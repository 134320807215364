import * as React from 'react';
import { SVGProps } from 'react';

const SvgCollapse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={24}
    viewBox="0 0 16 12"
    fill="none"
    {...props}
  >
    <path
      fill="#7B61FF"
      fillRule="evenodd"
      d="M0.292893 5.29282L5.29289 0.292818L6.70711 1.70703L3.41421 4.99992L6.5 4.99992L11.5 4.99992L11.5 6.99992L6.5 6.99992L3.41421 6.99992L6.70711 10.2928L5.29289 11.707L0.292893 6.70703C-0.0976315 6.31651 -0.0976315 5.68334 0.292893 5.29282ZM16 -6.99382e-07L14 -6.11959e-07L14 12L16 12L16 -6.99382e-07Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCollapse;
