import { Link, focusRingStyles } from '@squareup/dex-ui';
import { Paragraph30, Paragraph20 } from '@squareup/dex-ui-shared-base';
import { activeNavItemId } from '@squareup/dex-utils-docs-navigation';
import clsx from 'clsx';
import React, { FC, MouseEventHandler } from 'react';

import styles from './left-nav-items.module.css';

interface NavLinkProps {
  title: string;
  href?: string | undefined;
  isActive: boolean;
  depth: number;
  onClick?: MouseEventHandler | undefined;
}

export const LeftNavLink: FC<NavLinkProps> = ({
  title,
  href,
  isActive,
  depth,
  onClick,
}) => {
  const isTopLevel = depth === 0;
  const numSpaces = Math.max(0, depth - 1); // first two levels have no space
  const spaces = Array.from({ length: numSpaces });

  const Paragraph = isTopLevel ? Paragraph30 : Paragraph20;

  const cn = clsx(
    styles.link,
    isActive && styles.active,
    isTopLevel ? styles['top-level'] : styles['sub-level'],
    !href && styles['as-div']
  );

  const depthSpace = spaces.map((_, i) => (
    <span className={styles['depth-space']} key={i}></span>
  ));

  if (!href) {
    return (
      <Paragraph as="div" weight="medium" className={cn} onClick={onClick}>
        {depthSpace}
        {title}
      </Paragraph>
    );
  }

  return (
    <Link href={href} trackingId="nav-link" passHref omitAnchor={true}>
      <Paragraph
        as="a"
        id={isActive ? activeNavItemId : undefined}
        weight="medium"
        className={clsx(cn, focusRingStyles['focus-ring'])}
        onClick={onClick}
        trackingId={'left-nav-link'}
        trackingExtra={JSON.stringify({ title })}
      >
        {depthSpace}
        {title}
      </Paragraph>
    </Link>
  );
};
