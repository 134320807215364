import {
  NavItem,
  NavItemSubCategory,
  NavSet,
} from '@squareup/dex-types-shared-docs';
import { useMemo } from 'react';

const activeNavItemId = 'left-nav-active-item';

/**
 * Turns absolute item urls into relative paths.
 * @param url
 * @returns a relative url
 */
function makeDocUrlRelative(url: string | undefined) {
  const relative = url?.replace(/^\/(docs(-new)?)?\/?/, '') || '.';
  const hashRemoved = relative?.split('#').shift() as string;
  return hashRemoved;
}

function isDocUrlActive(url: string | undefined, currentPath: string) {
  return makeDocUrlRelative(url) === makeDocUrlRelative(currentPath);
}

function isItemExpanded(
  item: NavItem | undefined,
  openParents: Set<string>,
  currentPath: string
) {
  return (
    (item?.type === 'subcategory' && isDocUrlActive(item?.url, currentPath)) ||
    (item?.type === 'subcategory' ? openParents.has(item.id) : false)
  );
}

const useLeftNavItemsForPage = (
  leftNavId: string | undefined,
  navSet: NavSet | undefined
) => {
  const idToSubcategoryMap = useMemo(() => {
    const idMap = new Map<string, NavItemSubCategory>();
    const subcategoryItems = navSet?.items.filter(
      (item): item is NavItemSubCategory => item.type === 'subcategory'
    );

    if (subcategoryItems) {
      for (const item of subcategoryItems) {
        idMap.set(item.id, item);
      }
    }

    return idMap;
  }, [navSet]);

  if (!leftNavId) {
    return undefined;
  }

  return idToSubcategoryMap.get(leftNavId);
};

function getAncestorNavItemIdsForTarget(
  item: NavItem | undefined,
  targetItemUrl: string
): Set<string> | null {
  const result = getAncestorNavItemsForTarget(item, targetItemUrl);
  if (!result) {
    return null;
  }

  return new Set(result.map((item) => item.id));
}

/**
 * This performs a depth-first-search in its attempt to find the
 * current target URL.
 * It will return an ordered list of NavItemSubCategory ancestors,
 * starting from the root of the tree to the current item. The resulting
 * array does not contain the target item itself.
 * @param item The current item to process
 * @param targetItemUrl The target item url to find
 * @param parents An array of current ancestors we've found
 * @returns A list of ancestors discoverd, ordered from the root.
 */
function getAncestorNavItemsForTarget(
  item: NavItem | NavSet | undefined,
  targetItemUrl: string,
  parents: Array<NavItemSubCategory> = []
): Array<NavItemSubCategory> | null {
  if (!item) {
    return null;
  }

  // If they passed in the whole nav set, we walk all the items
  if (!(item as NavItem).type) {
    const navSet = item as NavSet;
    for (const subItem of navSet.items) {
      const result = getAncestorNavItemsForTarget(
        subItem,
        targetItemUrl,
        parents
      );

      if (result) {
        return result;
      }
    }

    return null;
  }

  const navItem = item as NavItem;

  if ('url' in navItem && isDocUrlActive(navItem.url, targetItemUrl)) {
    return parents;
  }

  // traverse sub items
  if (navItem.type === 'subcategory' && navItem.items) {
    for (const subItem of navItem.items) {
      const result = getAncestorNavItemsForTarget(subItem, targetItemUrl, [
        ...parents,
        navItem,
      ]);

      if (result) {
        return result;
      }
    }
  }
  return null;
}

/**
 * For a given href, returns whether it routes to the
 * docs site or not.
 * Note that this relies on the fact that it's running within
 * a docs page, because it assumes urls like "oauth/view" is relative
 * to the docs page
 * @param href The href to check
 * @returns true if it routes to the docs page, false otherwise
 */
function isDocsPageHref(href?: string | undefined | null): href is string {
  if (!href) {
    return false;
  }

  // Try to check if it's a full URL object
  try {
    const url = new URL(href);
    return url.pathname.startsWith('/docs/');
  } catch {
    // pass
  }

  // The url is not a structured one, so it must just be a basic string
  if (href.startsWith('/docs/')) {
    return true;
  } else if (href.startsWith('/')) {
    return false;
  } else {
    // If it doesn't start with a /, is must be a relative url
    return true;
  }
}

/**
 * For a given url, attempt to get the docs slug
 * @param href The href to check
 * @returns The slug if it is a docs page, and the params split out if it has any
 */
function getSlugFromDocsPage(href: string | undefined | null): string | null {
  if (!isDocsPageHref(href)) {
    return null;
  }

  // It's either an absolute url with `/docs/`, or a relative url in
  // which case the whole href is a slug
  const maybeSlug = href.split('/docs/')?.[1];

  const slug = maybeSlug ? maybeSlug : href;

  // Drop the # off the end if it exists
  return slug.split('#')?.[0] || slug;
}

/**
 * Gets URLSearchParams from a given slug. The slug may be an absolute URL, or a relative one
 * @param slug The slug to split
 * @returns An object containing the slug without the params, and a URLSearchParams object
 */
function splitParamsFromSlug(slug: string): {
  slug: string;
  params: URLSearchParams;
} {
  const [path, slugParams] = slug.split('?');
  if (!slugParams || !path) {
    return { slug, params: new URLSearchParams() };
  }

  return { slug: path, params: new URLSearchParams(slugParams) };
}

export {
  getAncestorNavItemIdsForTarget,
  getAncestorNavItemsForTarget,
  useLeftNavItemsForPage,
  makeDocUrlRelative,
  isDocUrlActive,
  isDocsPageHref,
  getSlugFromDocsPage,
  splitParamsFromSlug,
  isItemExpanded,
  activeNavItemId,
};
