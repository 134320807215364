interface ErrorDetails {
  title: string;
  content: string;
}

const error400 = {
  title: 'Bad request',
  content: `If you can't find what you're looking for, try our [docs homepage](/docs).\\
    You can also [contact support](https://squareup.com/help/us/en/contact?panel=BF53A9C8EF68) to get help.`,
};

const error404 = {
  title: 'Page not found',
  content: `If you can't find what you're looking for, try our [docs homepage](/docs).\\
    You can also [contact support](https://squareup.com/help/us/en/contact?panel=BF53A9C8EF68) to get help.`,
};

const error500 = {
  title: 'Internal server error',
  content: `There was an error loading this page. Try refreshing or coming back later.\\
    You can also [contact support](https://squareup.com/help/us/en/contact?panel=BF53A9C8EF68) to get help.`,
};

const errorToContentMap = new Map<number, ErrorDetails>([
  [400, error400],
  [404, error404],
  [500, error500],
]);

export { errorToContentMap, error404, error500, error400, type ErrorDetails };
