import { useEffect, useState } from 'react';

let ignitionValue: typeof import('@square/ignition') | null = null;

/**
 * This hook allows you to load the ignition library lazily, and it will
 * cache the result for future hook calls.
 * Because ignition is such a big library, we avoid loading it as a blocking dependency. It
 * leads to an extra 100kB gzipped.
 * Therefore, this will lazily load it, and save it in module-level state. This way, future calls
 * will be able to use it immediately.
 * @returns
 */
const useIgnitionLazy = () => {
  const [ignition, setIgnition] = useState<
    typeof import('@square/ignition') | null
  >(ignitionValue);

  useEffect(() => {
    if (ignition) {
      return;
    }

    async function load() {
      const ignitionLib = await import('@square/ignition');
      ignitionValue = ignitionLib;
      setIgnition(ignitionLib);
    }

    load().catch(() => null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ignition;
};

export { useIgnitionLazy };
