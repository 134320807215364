import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import { Grid, GridItemRenderProps } from '@squareup/dex-ui-shared-grid';
import { TrackedBox } from '@squareup/dex-ui-user-interaction-publisher';
import clsx from 'clsx';
import React, { ElementType, MouseEventHandler, ReactNode } from 'react';

import pageStyles from './page-layout.module.css';

export interface DocsPageLayoutProps {
  contents: ReactNode;
  LeftNavigation?: ReactNode | undefined;
  TopNavigation?: ReactNode | undefined;
  MobileNavigation?: ReactNode | undefined;
  Header: ReactNode | undefined;
  Footer: ElementType;
  contentFullWidth?: boolean;
  contentCenter?: boolean;
  leftNavCollapsed?: boolean;
  editor?: boolean;
  onMouseOver?: MouseEventHandler | undefined;
}

/**
 * Creates the general Doc Page app layout.
 * Unfortunately, both the top and left nav need to be fixed. The problem
 * with that is fixed items are absolutely positioned. That means they don't
 * respect the grid they were originally in.
 * We can, however, make the grid itself fixed! So we made the top-nav and header fixed in a grid
 * We tried to also make the left-nav in that same grid, but if that is done, it actually covers the whole page
 * and items aren't clickable. Therefore, we will put it outside the grid on its own.
 */
const DocsPageAppLayout = ({
  contents,
  Header,
  LeftNavigation,
  TopNavigation,
  MobileNavigation,
  Footer,
  contentFullWidth = false,
  contentCenter = false,
  leftNavCollapsed,
  editor,
  className,
  onMouseOver,
}: DocsPageLayoutProps & NullableClassName) => {
  const fixedTopRender = ({ styles: gridStyles }: GridItemRenderProps) => {
    return (
      <>
        <TrackedBox
          className={clsx(
            gridStyles['grid-item-full'],
            pageStyles['top-layout']
          )}
          trackingId="header"
        >
          {Header}
        </TrackedBox>
        {TopNavigation && (
          <TrackedBox
            trackingId="top-navigation"
            className={clsx(gridStyles['grid-item-full'])}
          >
            {TopNavigation}
          </TrackedBox>
        )}
        {MobileNavigation}
      </>
    );
  };

  const staticRender = ({ styles: gridStyles }: GridItemRenderProps) => {
    return (
      <>
        {LeftNavigation && (
          <Box
            className={clsx(
              gridStyles['grid-item-small'],
              pageStyles['left-nav']
            )}
          ></Box>
        )}
        <Box
          margin={{ vertical: '6x' }}
          className={clsx(
            pageStyles['page-main'],
            contentFullWidth && pageStyles['content-full-width'],
            contentFullWidth
              ? gridStyles['grid-item-full']
              : gridStyles['grid-item-medium'],
            editor && pageStyles['editor-page']
          )}
        >
          {contents}
          {/* Throwing this in a grid so that it matches the actual content */}
          <Grid
            variant="docs-content"
            render={() => {
              return (
                <TrackedBox
                  className={clsx(pageStyles.footer)}
                  trackingId="footer"
                  margin={{ vertical: '10x' }}
                >
                  <Footer />
                </TrackedBox>
              );
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Grid
        onMouseOver={onMouseOver}
        className={clsx(
          pageStyles['page-layout'],
          pageStyles['fixed-layout'],
          className
        )}
        variant="docs-app"
        render={fixedTopRender}
      />
      {LeftNavigation && (
        <TrackedBox
          onMouseOver={onMouseOver}
          className={clsx(
            pageStyles['page-layout'],
            pageStyles['fixed-layout'],
            pageStyles['left-layout'],
            leftNavCollapsed && pageStyles['collapsed']
          )}
          trackingId="left-navigation"
          testId="left-layout"
        >
          {LeftNavigation}
        </TrackedBox>
      )}
      <Grid
        onMouseOver={onMouseOver}
        className={clsx(
          pageStyles['page-layout'],
          pageStyles['static-layout'],
          contentCenter && pageStyles['content-center'],
          leftNavCollapsed && pageStyles['left-nav-collapsed'],
          className
        )}
        variant="docs-app"
        render={staticRender}
      />
    </>
  );
};

export { DocsPageAppLayout };
