import { Box } from '@squareup/dex-ui-shared-base';
import React from 'react';

import styles from './left-nav-items.module.css';

export const LeftNavDivider = () => {
  return (
    <Box
      margin={{ horizontal: '0.75x' }}
      className={styles.divider || ''}
      testId="left-nav-divider"
    />
  );
};
