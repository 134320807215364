import { NavSet } from '@squareup/dex-types-shared-docs';
import {
  useLeftNavItemsForPage,
  getAncestorNavItemIdsForTarget,
} from '@squareup/dex-utils-docs-navigation';
import React, { FC, MouseEventHandler } from 'react';

import { LeftNav } from './LeftNav';

interface LeftNavContainerProps {
  navSet: NavSet;
  currentPath: string;
  leftNavId?: string | undefined;
  toggleNav: MouseEventHandler;
  isCollapsed: boolean;
}

export const LeftNavContainer: FC<LeftNavContainerProps> = ({
  navSet,
  currentPath,
  leftNavId,
  toggleNav,
  isCollapsed,
}) => {
  const subCategory = useLeftNavItemsForPage(leftNavId, navSet);
  const openParents =
    getAncestorNavItemIdsForTarget(subCategory, currentPath) || new Set();

  if (!subCategory) {
    return null;
  }

  return (
    <LeftNav
      items={subCategory.items}
      openParents={openParents}
      currentPath={currentPath}
      toggleNav={toggleNav}
      isCollapsed={isCollapsed}
    />
  );
};
