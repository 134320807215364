import {
  isOptOutConsentModel,
  isOptOutRegion,
  nowAndOnOneTrustConsentChanged,
  shouldShowSettings,
  showCookieSettings,
} from '@square/onetrust-compliant-access';
import { SiteFooter } from '@squareup/dex-svelte-global-nav';
import { SiteFooter as SsrSiteFooter } from '@squareup/dex-svelte-global-nav/dist/ssr';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import {
  convertToEnvironmentUrl,
  getEnvironmentName,
} from '@squareup/dex-utils-environment';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { SvelteComponent } from '../SvelteComponent';

type FooterProps = {
  showCookiePreferences?: boolean;
  cookiePreferencesTitle?: string;
  wide?: boolean;
};

export const Footer: FunctionComponent<NullableClassName & FooterProps> = ({
  className,
  wide = false,
}) => {
  const [siteFooterProps, setSiteFooterProps] = useState(getProps({ wide }));

  useEffect(() => {
    nowAndOnOneTrustConsentChanged(() => {
      setSiteFooterProps(
        getProps({
          wide,
          showCookiePreferences: shouldShowSettings({ optOutEnabled: true }),
          cookiePreferencesTitle:
            isOptOutConsentModel() || isOptOutRegion()
              ? 'Opt-out of interest-based advertising'
              : 'Cookie preferences',
        })
      );
    });
  }, [setSiteFooterProps, wide]);

  const Children = () => (
    <SvelteComponent
      clientSideComponent={SiteFooter}
      // @ts-ignore: Unreachable code error
      serverSideComponent={SsrSiteFooter}
      componentProps={siteFooterProps}
    ></SvelteComponent>
  );

  // Svelte components do not accept classnames so for now add a div when needed.
  return className ? (
    <Box className={className}>
      <Children />
    </Box>
  ) : (
    <Children />
  );
};

function getProps(props: FooterProps) {
  const env = getEnvironmentName();
  return {
    wide: props.wide,
    eventCallback: () => null,
    onCookiePreferencesClicked: () => showCookieSettings(),
    squareUrl: (url: string) => convertToEnvironmentUrl(url, env),
    showCookiePreferences: props.showCookiePreferences,
    cookiePreferencesTitle: props.cookiePreferencesTitle,
  };
}
