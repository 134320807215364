import { Link, focusRingStyles } from '@squareup/dex-ui';
import { Paragraph20 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './top-nav-items.module.css';

interface NavLinkProps {
  title: string;
  href: string;
  isActive: boolean;
}

const TopNavLink: FC<NavLinkProps> = ({ title, href, isActive }) => {
  return (
    <Link href={href} trackingId="nav-link" passHref omitAnchor={true}>
      <Paragraph20
        as="a"
        weight="medium"
        trackingId="top-nav-link"
        trackingExtra={JSON.stringify({ title })}
        className={clsx(
          styles.link,
          isActive && styles.active,
          focusRingStyles['focus-ring']
        )}
      >
        {title}
      </Paragraph20>
    </Link>
  );
};

export { TopNavLink };
