'use client';
import { InteractiveEvent } from '@squareup/dex-types-shared-ui';
import { Box, BoxProps } from '@squareup/dex-ui-shared-base';
import React, { ReactNode, useCallback } from 'react';

type TrackedBoxProps = {
  trackingId: string;
  children: ReactNode | undefined;
} & BoxProps;

/**
 * When added to the React tree will create a tracking context around the box.
 * Upon user interaction of interactable child components will append the given
 * tracking ID to the tracking meta data generated.
 *
 * This can be useful to allow knowledge of the contextual area of the tree the
 * interactable elements live upon interaction.
 *
 * Example usages would include adding them to the page layout around each notable
 * area such as header, footer, navigation, main etc. As well as nesting within those
 * areas to gain more granular knowledge. These components can and should be nested.
 */
const TrackedBox = ({ trackingId, children, ...rest }: TrackedBoxProps) => {
  const decorateInteraction = useCallback(
    (e: InteractiveEvent) => {
      /**
       * I know, dirty.. Not seeing any native bag I can stuff this in.
       * Adding data to an event is generally discouraged as an anti pattern,
       * however that is mainly to not create two way data flows for components.
       * In this case this is an infrastructure piece we are not passing
       * renderable state here.
       */

      // eslint-disable-next-line no-param-reassign
      e.extraInteractionData = e.extraInteractionData || {};
      // eslint-disable-next-line no-param-reassign
      e.extraInteractionData.trackingIds =
        e.extraInteractionData.trackingIds || [];

      // Push to back of list as the areas can be nested.
      e.extraInteractionData.trackingIds.push(trackingId);
    },
    [trackingId]
  );

  return (
    <Box {...rest} onChange={decorateInteraction} onClick={decorateInteraction}>
      {children}
    </Box>
  );
};

export { TrackedBox, type TrackedBoxProps };
