import { Collapse } from '@squareup/dex-icons/dex/arrow';
import { NavItem } from '@squareup/dex-types-shared-docs';
import { NativeButton } from '@squareup/dex-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import { activeNavItemId } from '@squareup/dex-utils-docs-navigation';
import { isElementVisibleInScrollableElement } from '@squareup/dex-utils-dom';
import clsx from 'clsx';
import React, { FC, MouseEventHandler, useCallback } from 'react';

import { RecursiveNavItem } from './RecursiveNavItem';
import styles from './left-nav.module.css';

interface LeftNavProps {
  items: Array<NavItem> | undefined;
  openParents: Set<string>;
  currentPath: string;
  leftNavId?: string | undefined;
  toggleNav: MouseEventHandler;
  isCollapsed: boolean;
}

export const LeftNav: FC<LeftNavProps> = ({
  items = [],
  openParents,
  isCollapsed,
  currentPath,
  toggleNav,
}) => {
  const onLeftNavCreated = useCallback(
    (ref: HTMLDivElement) => {
      if (!ref) {
        return;
      }

      const activeItem = ref.querySelector(`#${activeNavItemId}`) as
        | HTMLElement
        | undefined;
      if (!activeItem) {
        return;
      }

      // Check if item is in view and scroll to it if not
      if (isElementVisibleInScrollableElement(activeItem, ref)) {
        return;
      }

      activeItem.scrollIntoView({ block: 'center', behavior: 'smooth' });
    },
    // This will force us to re-evaluate the scroll when the path changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPath]
  );

  return (
    <>
      <NativeButton
        trackingId="left-nav-toggle"
        aria-label="Toggle left navigation"
        testId="left-nav-toggle"
        className={clsx(
          styles['left-nav-toggle'],
          isCollapsed && styles['collapsed']
        )}
        onClick={toggleNav}
      >
        <Collapse />
      </NativeButton>
      <Box
        border={{ line: { right: 'standard' } }}
        padding={{ vertical: '2x', horizontal: '3x' }}
        className={styles['left-nav']}
        testId="left-nav"
        ref={onLeftNavCreated}
      >
        {items.map((item, i) => {
          const keyValue =
            item.type === 'link' || item.type === 'subcategory'
              ? `${item.url}${item.title}${i}`
              : `${item.style}${i}`;

          return (
            <RecursiveNavItem
              // To ensure the item resets state when it's selected/expanded,
              // we need to use a unique key
              key={keyValue}
              item={item}
              openParents={openParents}
              depth={0}
              currentPath={currentPath}
            />
          );
        })}
      </Box>
    </>
  );
};
