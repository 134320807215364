import type { generateCode } from '@square/ignition';
import {
  useIgnitionLazy,
  useOasGraphLazy,
} from '@squareup/dex-data-access-oas';
import { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useCodeLanguage } from '@squareup/dex-store-access-shared-main-selectors';
import {
  CodeExampleLanguage,
  codeExampleLanguages,
} from '@squareup/dex-types-oas';
import { DocsQueryParams } from '@squareup/dex-types-shared-docs';
import {
  CodeBlockFooterLink,
  CodeDropdownButton,
  CodeDropdownButtonItem,
} from '@squareup/dex-ui';
import { Paragraph10, Paragraph20 } from '@squareup/dex-ui-shared-base';
import { MarkdownCodeBlock } from '@squareup/dex-ui-shared-markdown-components';
import { getNextPublicBaseURL } from '@squareup/dex-utils-environment';
import {
  codeLanguageDisplayMap,
  languageToIgnitionLanguage,
} from '@squareup/dex-utils-oas';
import { humanizeName } from '@squareup/dex-utils-text';
import jsonic from 'jsonic';
import { useRouter } from 'next/router';
import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';

interface MarkdownCodeBlockProps {
  content: string;
  language: string;
  lineNumbers?: boolean;
  openInEditor?: boolean;
}

const isIgnitionEndpoint = (language?: string) => {
  return language?.startsWith('endpoint:');
};

const MarkdownIgnitionCodeBlock: FC<
  PropsWithChildren<MarkdownCodeBlockProps>
> = ({ content, language, lineNumbers = true, openInEditor = false }) => {
  const {
    query: {
      [DocsQueryParams.unpublishedOas]: unpublishedOasId,
      [DocsQueryParams.legacyUnpublishedOas]: legacyUnpublishedOasId,
    },
  } = useRouter();

  const unpublishedId = (unpublishedOasId || legacyUnpublishedOasId) as
    | string
    | undefined;

  const ignition = useIgnitionLazy();

  const { graph } = useOasGraphLazy(
    {
      grammar: 'open-api-3',
      version: unpublishedId,
      isUnpublished: Boolean(unpublishedId),
      namespace: 'square',
    },
    getDeveloperApi()
  );

  const [codeLanguage, setCodeLanguage] = useCodeLanguage();

  const requestData = useMemo(() => {
    try {
      return jsonic(content) as Parameters<typeof generateCode>[2];
    } catch {
      return null;
    }
  }, [content]);

  const endpointName = language.split('-')?.[1] || '';

  const endpoint = useMemo(() => {
    if (!graph) {
      return null;
    }

    return graph.getEndpoint(endpointName);
  }, [graph, endpointName]);

  const code = useMemo(() => {
    if (!endpoint) {
      return '';
    }

    if (!requestData) {
      return '';
    }

    if (!ignition) {
      return '';
    }

    try {
      return ignition.generateCode(
        languageToIgnitionLanguage(codeLanguage) || ignition.Language.curl,
        endpoint,
        requestData
      );
    } catch {
      return content;
    }
  }, [endpoint, requestData, ignition, codeLanguage, content]);

  const languagesMemo = useMemo(() => {
    return codeExampleLanguages.map((lang) => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name: codeLanguageDisplayMap.get(lang)!,
      value: lang,
    }));
  }, []);

  const onLanguageChangedCallback = useCallback(
    (item: CodeDropdownButtonItem<{ value: CodeExampleLanguage }>) => {
      setCodeLanguage(item.value);
    },
    [setCodeLanguage]
  );

  const apiExplorerLink = useMemo(() => {
    if (!endpoint) {
      return '';
    }

    if (!requestData) {
      return '';
    }

    if (!ignition) {
      return '';
    }

    const params =
      ignition.ApiExplorerParameterHandler.convertCodegenInputToV1(requestData);

    const explorerUrl = `${getNextPublicBaseURL()}/explorer/square/${
      endpoint?.api?.id
    }-api/${endpoint.id}`;

    return ignition.ShareApiHandler.generateLink(
      params,
      explorerUrl,
      endpoint?.squareVersion || '',
      requestData.environment || 'sandbox'
    );
  }, [endpoint, requestData, ignition]);

  const codeDropdown = (
    <CodeDropdownButton
      buttonTestId={'code-block-ignition-language-dropdown'}
      selected={codeLanguageDisplayMap.get(codeLanguage) || ''}
      onSelected={onLanguageChangedCallback}
      items={languagesMemo}
    ></CodeDropdownButton>
  );

  const apiExplorerButton = (
    <CodeBlockFooterLink
      href={apiExplorerLink}
      trackingId={'api-explorer-link'}
      testId={'api-explorer-link'}
      target="_blank"
    >
      <Paragraph10 weight={'semi-bold'}>Try in API Explorer</Paragraph10>
    </CodeBlockFooterLink>
  );

  return (
    <>
      <MarkdownCodeBlock
        content={code}
        language={codeLanguage}
        lineNumbers={lineNumbers}
        floatingHeader={false}
        isLoading={!graph}
        title={
          <Paragraph20 weight={'semi-bold'}>
            {humanizeName(endpointName)}
          </Paragraph20>
        }
        actionGroupBlock={codeDropdown}
        footerBlock={apiExplorerLink && apiExplorerButton}
        openInEditor={openInEditor}
      />
    </>
  );
};

export { MarkdownIgnitionCodeBlock, isIgnitionEndpoint };
