import { X } from '@squareup/dex-icons/market/action';
import ArrowLeft from '@squareup/dex-icons/market/arrow/Left';
import {
  NavItemLink,
  NavItemSubCategory,
  NavSet,
} from '@squareup/dex-types-shared-docs';
import { NativeButton } from '@squareup/dex-ui';
import { Box, Heading30 } from '@squareup/dex-ui-shared-base';
import {
  getAncestorNavItemIdsForTarget,
  makeDocUrlRelative,
  useLeftNavItemsForPage,
} from '@squareup/dex-utils-docs-navigation';
import clsx from 'clsx';
import React, { FC, MouseEventHandler, useEffect, useState } from 'react';

import { MobileNavLink } from './MobileNavLink';
import styles from './mobile-nav.module.css';

interface MobileNavProps {
  navSet: NavSet;
  currentPath: string;
  leftNavId?: string | undefined;
  onClose: MouseEventHandler;
  isOpen: boolean;
}

const MobileNav: FC<MobileNavProps> = ({
  isOpen,
  navSet,
  onClose,
  leftNavId,
  currentPath,
}) => {
  const [items, setItems] = useState(navSet.items);
  const [parents, setParents] = useState<Array<NavItemSubCategory>>([]);
  const [title, setTitle] = useState('Docs');

  const subCategory = useLeftNavItemsForPage(leftNavId, navSet);
  const activeParents =
    getAncestorNavItemIdsForTarget(subCategory, currentPath) || new Set();

  const stepInto = (item: NavItemSubCategory) => {
    setTitle(item.title);
    setItems(item.items);
    setParents((par) => [...par, item]);
  };

  const stepBack = () => {
    const updatedParents = parents.slice(0, -1);
    const prev = updatedParents.slice(-1)[0];
    if (prev) {
      setTitle(prev.title);
      setItems(prev.items);
      setParents(updatedParents);
    } else {
      setTitle('Docs');
      setItems(navSet.items);
      setParents([]);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [isOpen]);

  return (
    <Box
      padding={{ horizontal: '3x', vertical: '3x' }}
      className={clsx(styles['mobile-nav'], isOpen && styles['open'])}
      testId="docs-mobile-nav"
    >
      <Box
        className={clsx(
          styles['top'],
          parents.length > 0 && styles['sub-page']
        )}
      >
        <Box>
          {parents.length > 0 && (
            <NativeButton
              trackingId="mobile-nav-step-back"
              testId="mobile-nav-step-back"
              onClick={stepBack}
              margin={{ bottom: '3x' }}
              className={styles['mobile-nav-back']}
            >
              <ArrowLeft />
            </NativeButton>
          )}
          <Heading30 testId="mobile-nav-title">{title}</Heading30>
        </Box>
        <NativeButton
          trackingId="docs-mobile-nav-close-button"
          onClick={onClose}
          className={styles['close-button']}
        >
          <X />
        </NativeButton>
      </Box>
      <Box margin={{ top: '7x' }}>
        {items
          .filter(
            (item): item is NavItemSubCategory | NavItemLink =>
              item.type === 'link' || item.type === 'subcategory'
          )
          .map((item, i) => {
            const activeParent = 'id' in item && activeParents.has(item.id);
            const currentPage =
              makeDocUrlRelative(item.url) === makeDocUrlRelative(currentPath);
            return (
              <MobileNavLink
                key={item.title + i}
                item={item}
                isActive={activeParent || currentPage}
                stepInto={stepInto}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export { MobileNav };
