import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import {
  MarkdownBasicFence,
  basicFenceSchema,
} from '@squareup/dex-ui-shared-markdown-components';
import { HighlightLanguage } from '@squareup/dex-utils-highlight';
import React, { FC, PropsWithChildren } from 'react';

import {
  MarkdownIgnitionCodeBlock,
  isIgnitionEndpoint,
} from './MarkdownIgnitionCodeBlock';

interface MarkdownFenceProps {
  content: string;
  language: HighlightLanguage | string;
  lineNumbers?: boolean;
  openInEditor?: boolean;
}

const MarkdownFence: FC<PropsWithChildren<MarkdownFenceProps>> = ({
  content,
  language,
  lineNumbers = true,
  openInEditor = false,
}) => {
  if (isIgnitionEndpoint(language)) {
    return (
      <MarkdownIgnitionCodeBlock
        content={content}
        language={language}
        lineNumbers={lineNumbers}
        openInEditor={openInEditor}
      />
    );
  } else {
    return (
      <MarkdownBasicFence
        content={content}
        language={language as HighlightLanguage}
        lineNumbers={lineNumbers}
        openInEditor={openInEditor}
      />
    );
  }
};

const schema = {
  ...basicFenceSchema,
  render: 'MarkdownFence',
};

const fence: MarkdownNode = {
  node: {
    nodeType: 'fence',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownFence,
  },
};

export { fence, MarkdownFence };
