import type { Language } from '@square/ignition';
import { CodeExampleLanguage } from '@squareup/dex-types-oas';

const codeLanguageDisplayMap = new Map<CodeExampleLanguage, string>([
  ['curl', 'cURL'],
  ['java', 'Java'],
  ['csharp', 'C#'],
  ['php', 'PHP'],
  ['javascript', 'Node.js'],
  ['python', 'Python'],
  ['ruby', 'Ruby'],
]);

const langMap = new Map<CodeExampleLanguage, Language>([
  ['curl', 'CURL' as Language],
  ['ruby', 'RUBY' as Language],
  ['csharp', 'CSHARP' as Language],
  ['java', 'JAVA' as Language],
  ['javascript', 'JAVASCRIPT' as Language],
  ['python', 'PYTHON' as Language],
  ['php', 'PHP' as Language],
]);

function languageToIgnitionLanguage(
  lang: CodeExampleLanguage
): Language | undefined {
  return langMap.get(lang);
}

export { languageToIgnitionLanguage, codeLanguageDisplayMap };
