import * as React from 'react';
import { SVGProps } from 'react';

const SvgX = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.71 18.71 5.29-5.3 5.29 5.3 1.42-1.42-5.3-5.29 5.3-5.29-1.42-1.42-5.29 5.3-5.29-5.3-1.42 1.42 5.3 5.29-5.3 5.29 1.42 1.42Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgX;
