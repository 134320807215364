import { NavSet } from '@squareup/dex-types-shared-docs';
import { Box } from '@squareup/dex-ui-shared-base';
import { makeDocUrlRelative } from '@squareup/dex-utils-docs-navigation';
import clsx from 'clsx';
import React, { FC } from 'react';

import { TopNavDivider, TopNavSpace } from './TopNavDivider';
import { TopNavLink } from './TopNavLink';
import styles from './top-nav.module.css';

interface DocsTopNavProps {
  navSet?: NavSet | null | undefined;
  currentPath: string;
  leftNavId?: string | undefined;
}

const TopNav: FC<DocsTopNavProps> = ({ navSet, currentPath, leftNavId }) => {
  const desktopTopNav = navSet ? (
    <Box
      border={{ line: { top: 'standard', bottom: 'standard' } }}
      padding={{ horizontal: '3x' }}
      className={clsx(styles['top-nav'], styles['desktop'])}
      testId="top-nav-desktop"
    >
      {navSet?.items.map((item, i) => {
        if (item.type === 'divider' && item.style === 'divider') {
          return <TopNavDivider key={i} />;
        }
        if (item.type === 'divider' && item.style === 'space') {
          return <TopNavSpace key={i} />;
        }
        if (item.type === 'link') {
          const isActive =
            makeDocUrlRelative(item.url) === makeDocUrlRelative(currentPath);
          return (
            <TopNavLink
              key={i}
              title={item.title}
              href={makeDocUrlRelative(item.url)}
              data-test-href={makeDocUrlRelative(item.url)}
              isActive={isActive}
            />
          );
        }
        if (item.type === 'subcategory') {
          const isActive = Boolean(leftNavId && item.id === leftNavId);
          return (
            <TopNavLink
              key={i}
              title={item.title}
              href={makeDocUrlRelative(item.url)}
              isActive={isActive}
            />
          );
        }

        return null;
      })}
    </Box>
  ) : undefined;

  return <>{desktopTopNav}</>;
};

export { TopNav };
