import type { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
import { SpecificationVersionInfo } from '@squareup/dex-types-oas-path';
import { useEffect } from 'react';

type DeveloperApi = ReturnType<typeof getDeveloperApi>;

const oasMap = new Map<string, string>();

const getOasVersion = (options: SpecificationVersionInfo) => {
  return options.version || 'latest';
};

/**
 * This hook allows you to load oas content lazily, but keeps it outside of redux.
 * Since the OAS is so big, it slows down everything to push the state into redux and RTKQ
 * @returns
 */
const useOasLazy = (
  options: SpecificationVersionInfo,
  devApi: DeveloperApi
) => {
  const oas = oasMap.get(getOasVersion(options));

  const [oasTrigger, oasResult] = devApi.useLazyGetSpecificationQuery();
  useEffect(() => {
    if (oasResult.isLoading || oasResult.error || oas) {
      return;
    }

    oasTrigger(options, true).then((result) => {
      if (result.data?.content.content) {
        oasMap.set(getOasVersion(options), result.data.content.content);
      }
    });
  }, [oas, oasResult.error, oasResult.isLoading, oasTrigger, options]);

  return {
    ...oasResult,
    oas: oas || oasResult.data?.content.content,
  };
};

export { useOasLazy, getOasVersion };
