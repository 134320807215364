import type { Graph } from '@square/ignition';
import { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
import { SpecificationVersionInfo } from '@squareup/dex-types-oas-path';
import { useMemo } from 'react';

import { useIgnitionLazy } from './ignition-hooks';
import { getOasVersion, useOasLazy } from './oas-hooks';

type DeveloperApi = ReturnType<typeof getDeveloperApi>;

// Keep this in module-level state so that all callers can share the graph
// It will also avoid re-parsing the graph
const graphMap = new Map<string, Graph>();

/**
 * This is a helper hook that basically combines the `useIgnitionLazy` and `useOasLazy` hooks to return
 * a valid graph object from ignition.
 * @param options Options needed to fetch an OAS
 * @param devApi The developer api to make the fetch call
 * @returns A valid graph object if available, or null otherwise
 */
const useOasGraphLazy = (
  options: SpecificationVersionInfo,
  devApi: DeveloperApi
) => {
  const ignition = useIgnitionLazy();
  const { oas, ...oasResults } = useOasLazy(options, devApi);

  const graph = useMemo(() => {
    const oasVersion = getOasVersion(options);
    if (graphMap.has(oasVersion)) {
      return graphMap.get(oasVersion);
    }

    if (!oas || !ignition) {
      return null;
    }

    const oasGraph = ignition.Graph.fromJSON(oas);
    if (!oasGraph) {
      return null;
    }

    graphMap.set(oasVersion, oasGraph);

    return oasGraph;
  }, [ignition, oas, options]);

  return {
    ...oasResults,
    isLoading: !ignition || oasResults.isLoading,
    graph,
  };
};

export { useOasGraphLazy };
