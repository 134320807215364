import { ChevronRight } from '@squareup/dex-icons/dex/control';
import {
  NavItemLink,
  NavItemSubCategory,
} from '@squareup/dex-types-shared-docs';
import { Link, NativeButton } from '@squareup/dex-ui';
import { Box, Paragraph20 } from '@squareup/dex-ui-shared-base';
import { makeDocUrlRelative } from '@squareup/dex-utils-docs-navigation';
import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './mobile-nav.module.css';

interface NavLinkProps {
  item: NavItemSubCategory | NavItemLink;
  isActive: boolean;
  stepInto: Function;
}

const MobileNavLink: FC<NavLinkProps> = ({ item, isActive, stepInto }) => {
  return (
    <Box
      className={styles['nav-item']}
      margin={{ vertical: '2x' }}
      padding={{ bottom: '2x' }}
      border={{ line: { bottom: 'standard' } }}
      testId="mobile-nav-item"
    >
      <Link
        href={makeDocUrlRelative(item.url)}
        trackingId="nav-link"
        passHref
        omitAnchor={true}
      >
        <Paragraph20
          as="a"
          weight="medium"
          className={clsx(styles.link, isActive && styles.active)}
        >
          {item.title}
        </Paragraph20>
      </Link>
      {item.type === 'subcategory' && item.items.length > 0 && (
        <NativeButton
          trackingId="mobile-nav-step-into"
          onClick={() => stepInto(item)}
        >
          <ChevronRight />
        </NativeButton>
      )}
    </Box>
  );
};

export { MobileNavLink };
