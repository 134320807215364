import { Box } from '@squareup/dex-ui-shared-base';
import React from 'react';

import styles from './top-nav-items.module.css';

const TopNavDivider = () => {
  return (
    <Box
      margin={{ horizontal: '0.75x' }}
      className={styles.divider}
      testId="top-nav-divider"
    />
  );
};

const TopNavSpace = () => {
  return <Box className={styles.space} testId="top-nav-space"></Box>;
};

export { TopNavDivider, TopNavSpace };
