import * as React from 'react';
import { SVGProps } from 'react';

const SvgChevronRight = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5 4.586 4 4-4 4L6.414 14l5.414-5.414-5.414-5.414L5 4.586Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgChevronRight;
