import { ChevronDown } from '@squareup/dex-icons/dex/control';
import { NavItem } from '@squareup/dex-types-shared-docs';
import { NativeButton } from '@squareup/dex-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import {
  isDocUrlActive,
  isItemExpanded,
  makeDocUrlRelative,
} from '@squareup/dex-utils-docs-navigation';
import clsx from 'clsx';
import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useId,
  useState,
} from 'react';

import { LeftNavDivider } from './LeftNavDivider';
import { LeftNavLink } from './LeftNavLink';
import styles from './recursive-nav-item.module.css';

interface RecursiveNavItemProps {
  item: NavItem | undefined;
  openParents: Set<string>;
  depth: number;
  currentPath: string;
}

const RecursiveNavItem: FC<RecursiveNavItemProps> = ({
  item,
  openParents,
  depth,
  currentPath,
}) => {
  // active items and all parent subcategories start as expanded
  const defaultExpanded = isItemExpanded(item, openParents, currentPath);

  const [expanded, setExpanded] = useState(defaultExpanded);
  const toggleExpanded = () => setExpanded((a) => !a);

  const sublistId = useId();

  useEffect(() => {
    if (defaultExpanded && !expanded) {
      setExpanded(defaultExpanded);
    }
    // Only run this if the current path ever changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  if (!item) {
    return null;
  }
  /* Divider */
  if (item.type === 'divider') {
    return <LeftNavDivider />;
  }
  /* Link */
  if (item.type === 'link') {
    return (
      <Box
        className={clsx(
          styles['nav-item-wrap'],
          depth === 0 && styles['top-level']
        )}
      >
        <LeftNavLink
          title={item.title}
          href={makeDocUrlRelative(item.url)}
          data-test-href={makeDocUrlRelative(item.url)}
          isActive={isDocUrlActive(item.url, currentPath)}
          depth={depth}
        />
      </Box>
    );
  }
  /* Subcategory */
  if (item.type === 'subcategory') {
    const isActive = isDocUrlActive(item.url, currentPath);

    const handleSubcatClick: MouseEventHandler = (event) => {
      if (isActive || !item.url) {
        toggleExpanded();
        event.preventDefault();
      }
    };

    return (
      <>
        <Box
          className={clsx(
            styles['nav-item-wrap'],
            depth === 0 && styles['top-level'],
            depth !== 0 && styles['inner-sub-cat']
          )}
          aria-expanded={expanded ? 'true' : 'false'}
          aria-controls={sublistId}
        >
          <LeftNavLink
            title={item.title}
            href={item.url ? makeDocUrlRelative(item.url) : undefined}
            isActive={isActive}
            depth={depth}
            onClick={handleSubcatClick}
          />
          <NativeButton
            trackingId="expand-nav-items"
            testId="expand-nav-items"
            aria-label="Expand navigation items"
            className={clsx(
              styles['expand-button'],
              expanded && styles['expanded']
            )}
            onClick={toggleExpanded}
          >
            <ChevronDown />
          </NativeButton>
        </Box>
        {(item.items || []).length > 0 && (
          <Box
            id={sublistId}
            className={clsx(
              styles['items-container'],
              expanded && styles.expanded
            )}
            inert={!expanded ? 'true' : undefined}
            role="region"
          >
            <Box className={styles.content}>
              {item.items.map((subItem, i) => {
                const keyValue =
                  subItem.type === 'link' || subItem.type === 'subcategory'
                    ? `${subItem.url}${subItem.title}${i}`
                    : `${subItem.style}${i}`;

                return (
                  <RecursiveNavItem
                    key={keyValue}
                    openParents={openParents}
                    currentPath={currentPath}
                    depth={depth + 1}
                    item={subItem}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </>
    );
  }
  return null;
};

// We don't want to rerender the nav item if it's not active or expanded, or
// if it wasn't previously active or expanded
const RecursiveNavItemMemoized = React.memo(RecursiveNavItem, (prev, next) => {
  const prevItemActive =
    prev?.item?.type === 'link' &&
    prev.item.url &&
    isDocUrlActive(prev.item.url, prev.currentPath);

  const nextItemActive =
    next?.item?.type === 'link' &&
    next.item.url &&
    isDocUrlActive(next.item.url, next.currentPath);

  return (
    !prevItemActive &&
    !nextItemActive &&
    !isItemExpanded(next.item, next.openParents, next.currentPath) &&
    !isItemExpanded(prev.item, prev.openParents, prev.currentPath)
  );
});
RecursiveNavItemMemoized.displayName = 'RecursiveNavItem';

export { RecursiveNavItemMemoized as RecursiveNavItem };
